import React from 'react';
import { Box, Link, Card, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
    AppBar,
    Toolbar,
    Typography,
    Container,
    Paper,
} from '@material-ui/core';

import { Assets } from 'enums';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar: {
        marginBottom: theme.spacing(3),
    },
    content: {
        padding: theme.spacing(3),
        textAlign: 'left',
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        alignItems: 'center',
    },
    logo: {
        width: '100%',
        maxWidth: '400px',
    },
}));


function ContactUs() {
    const classes = useStyles();
    const address = "123 Main Street, Anytown, AT 12345";
    const email = "kumar@indiapolls.com";
    const mapSrc = "https://www.google.com/maps/embed?...";

    return (
        <div className={classes.root}>
            <AppBar position="static" className={classes.appBar}>
                <Toolbar>
                    <Typography variant="h6"> ग्राहक सेवा</Typography>
                </Toolbar>
            </AppBar>
            <Container maxWidth="lg" className={classes.content}>
                <Paper className={classes.paper}>
                    <img alt="Logo" src={Assets.Logo2} className={classes.logo} />
                    <Typography variant="h4" gutterBottom>
                        ग्राहक सेवा
                    </Typography>
                    <p>किसी भी सहायता या समर्थन प्रश्नों के लिए, हमारी समर्पित टीम आपकी सहायता के लिए उपस्थित है। कृपया किसी भी समय हमसे संपर्क करने के लिए स्वतंत्र महसूस करें, और हम एक तेज़ और सहायक प्रतिक्रिया सुनिश्चित करेंगे।</p>
                    <Typography variant="h6">ईमेल:</Typography>
                    <Link href={`mailto:${email}`}>{email}</Link>
                </Paper>
            </Container>
        </div>
    );
}

export default ContactUs;
