import React, { useState, useEffect } from 'react';
import Language from "../../Languages/Login/content.json"
const generateRandomString = (length) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
};

const SimpleCaptcha = (props) => {
   
    const [captchaText, setCaptchaText] = useState(generateRandomString(6));
    const [userInput, setUserInput] = useState('');
    const [isValid, setIsValid] = useState(null);
    

    const regenerateCaptcha = () => {
        setCaptchaText(generateRandomString(6));
        setUserInput('');
        setIsValid(null);
    };

    const handleInputChange = (e) => {
        const value = e.target.value;
        setUserInput(value);
        if (value === captchaText) {
            setIsValid(true);
            props.updateCaptcha(true);
            props.onValid(true);
        } else {
            setIsValid(false);
            props.updateCaptcha(false);
            props.onValid(false);
        }
    };

    return (
        <div style={{fontSize:`${window.matchMedia("(max-width: 768px)").matches ? "20px" : "16px"}`}}> 
            <img src={`https://dummyimage.com/${window.matchMedia("(max-width: 768px)").matches ? "520x190" : "370x100"}/515356/fff.png&text=${captchaText}`} alt="Captcha" style={{borderRadius:"0.5rem"}} width={"90rem"}/>
            <br />
            <input type="text" value={userInput} onChange={handleInputChange} className='mt-3 p-2' style={{border:"1px solid #999999",borderRadius:"0.75rem", width:"8rem"}}/>
            <button className="ml-2 text-white" type='button' onClick={regenerateCaptcha} style={{backgroundColor:"#046A38",borderRadius:"0.65rem"}}>{props.language === 'hi' ? "पुनः बनाए" : "Regenerate"}</button>
            {isValid === true && <p style={{ color: 'green' }}>{props.language === 'hi' ? "कैप्चा मान्य है!" : "Captcha is valid! ✔️"}</p>}
            {isValid === false && <p style={{ color: 'red' }}>{props.language === 'hi' ? "कैप्चा अमान्य है. कृपया पुन: प्रयास करें।" : "Captcha is invalid. Please try again. ❌"}</p>}
        </div>
    );
};

export default SimpleCaptcha;
