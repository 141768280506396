import { makeStyles, Paper, Typography } from "@material-ui/core";
import { Assets } from "enums";
import { Container } from '@material-ui/core';

function SurveyHold(){
    
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar: {
        marginBottom: theme.spacing(3),
    },
    content: {
        padding: theme.spacing(3),
        textAlign: 'left',
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        alignItems: 'center',
    },
    logo: {
        width: '100%',
        maxWidth: '400px', // Adjust the maximum width as needed
    },
    grow: {
        flexGrow: 1,
    },
}));

const classes = useStyles();
    return(
        <div className={classes.root}>
             <Container maxWidth='lg' className={classes.content}>
            <Paper className={classes.paper}>
                <img alt="Logo" src={Assets.Logo2} className={classes.logo} />
            </Paper>
          <div className={classes.paper} style={{color:"red"}}>Currently survey is on hold we will let you know when survey is live.</div>
          </Container>
        </div>
    )
}
export default SurveyHold;