import React from 'react';
import { Alert, Modal, Spinner } from 'react-bootstrap';
import { Show } from 'Layout';
import { PageStatus } from 'enums';
import {AuthAPI} from "../../API";
import { Container } from '@material-ui/core';
import {reduxForm} from "redux-form";
import {withRouter} from "react-router";
import {authBasicProfile, authSuccess, authSuccessLastStep} from "../auth.actions";
import {connect} from "react-redux";
import Language from "../../Languages/Login/content.json"

class OtpVerify4RequestApproval extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            status: PageStatus.None,
            error: null,
            phoneNumber: '',
            otp: '',
            otpSend: false,
            pageContent: this.props.language === 'hi' ? Language.OTPVerifyHindi : Language.OTPVerifyEnglish,
            user: null,
            email : "",
        };
    }

    async loadProfile () {
        Promise.resolve()
          .then(() => {
            if (!this.props.userId) {
              return Promise.reject(new Error("Invalid ID"));
            }
            return AuthAPI.profile(this.props.userId);
          })
          .then((user) => {
            if (!!user) {
              this.setState({email : user.dataValues.email, phoneNumber: user.dataValues.phoneNumber});
            }
          })
          .catch((error) => {
            alert(error);
          });
    };

    componentDidMount() {
        this.loadProfile();
    }

    removeModalVaues() {
        this.setState({ otp: '', otpSend: false});
        this.props.onClose();
      }

    formValues() {
        return {
            phoneNumber: this.state.phoneNumber,
            userId: this.props.userId,
            type :"Email"
        };
    }

    onSubmit() {
        if (this.state.email) {
            return this.sendOtpToMobile();
        }
    }

    sendOtpToMobile() {
        if(this.state.email) {
            const valuesIn = this.formValues();
            return Promise.resolve()
                .then(() => this.setState({status: PageStatus.Submitting}))
                .then(() => AuthAPI.sentOTPForapproval(valuesIn))
                .then((profile) => {
                    this.setState({status: PageStatus.Submitted, otpSend: true, user: profile });
                })
                .catch((error) => {
                    this.setState({status: PageStatus.Error, error: error.message});
                });
        }
    }

    handleVerify = () => {
        if (this.state.user && this.state.otp) {
            let obj = { otp: this.state.otp, userId: this.props.userId }
            return Promise.resolve()
                .then(() => this.setState({status: PageStatus.Submitting}))
                .then(() => AuthAPI.verifyOTPForApproval(obj))
                .then((responseData) => {
                    this.setState({status: PageStatus.Submitted})
                    this.props.onSubmit(this.state.otp);
                    this.setState({ otp: '', otpSend: false, })
                })
                .catch((error) => {
                    this.setState({status: PageStatus.Error, error: error.message});
                });
        }
    }

    resendOtp() {
        if (this.state.email) {
            if (this.state.user && this.state.email) {
                let obj = {phoneNumber: this.state.phoneNumber, userId: this.props.userId, type: "Email"}
                return Promise.resolve()
                    .then(() => this.setState({status: PageStatus.Submitting}))
                    .then(() => AuthAPI.sentOTPForapproval(obj))
                    .then((profile) => {
                        this.setState({status: PageStatus.Submitted, otpSend: true });
                    })
                    .catch((error) => {
                        this.setState({status: PageStatus.Error, error: error.message});
                    });
            }
        }
    }

    checkNumberValidation(e) {
        this.loadProfile();
        // if (e.target.value.length <= 10) { this.setState({ phoneNumber: e.target.value });  }
    }

    checkOtpLengthValidation(e) {
        if (e.target.value.length <= 4) { this.setState({ otp: e.target.value });  }
    }

    render() {
        let { pageContent } = this.state
        pageContent = this.props.language === 'hi' ? Language.OTPVerifyHindi : Language.OTPVerifyEnglish
        return (
            <Modal
                centered
                size="sm"
                backdrop="static"
                onHide={()=>{this.removeModalVaues()}}
                show={this.props.show}
                style={{ zIndex: 1201 }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        OTP Verification
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ maxHeight: '100vh', overflow: 'auto' }}>
                    <Container maxWidth="lg">
                        <Show when={this.state.status === PageStatus.Loading}>
                            <div className="d-flex justify-content-center w-100 p-5">
                                <Spinner animation="border" variant="primary" />
                            </div>
                        </Show>

                        <Alert variant="danger" show={this.state.status === PageStatus.Error}>
                            {this.state.error === 'OTP must be valid!' ? (this.props.language === 'hi' ? 'ओटीपी मान्य नहीं है।' : 'Not a valid OTP') : this.state.error}
                        </Alert>


                            <div className="row">
                                    <label htmlFor="title">Email</label>
                                    <input
                                        className="form-control"
                                        type='text'
                                        name="email"
                                        title=""
                                        onChange={(e) => this.checkNumberValidation(e)}
                                        value={this.state.email}
                                        placeholder={pageContent.cMPlaceholder}
                                        required
                                        disabled
                                    />
                            </div>
                            <hr />
                        <Show when={this.state.otpSend === false}>
                            <div className="mt-2 d-flex justify-content-center">
                                <button
                                    type="submit"
                                    disabled={!this.state.email}
                                    onClick={() => this.onSubmit()}
                                    className="btn btn-primary mr-3"
                                >
                                    {pageContent.cSendOtp}
                                </button>
                            </div>
                        </Show>

                        <Show when={this.state.otpSend === true}>
                                <div className="row">
                                    <label htmlFor="title">{pageContent.cPlaceholder}</label>
                                    <input
                                        className="form-control"
                                        type='number'
                                        name="otp"
                                        title=""
                                        onChange={(e) => this.checkOtpLengthValidation(e)}
                                        value={this.state.otp}
                                        placeholder={pageContent.cMPlaceholder}
                                        required
                                    />
                                </div>
                                <hr />
                                <div className="mt-2 d-flex justify-content-center">
                                <Show when={this.state.otpSend === true}>
                                <button
                                                type="submit"
                                                disabled={!this.state.email}
                                                onClick={() => this.resendOtp()}
                                                className="btn btn-primary mr-3"
                                                style={{fontSize: '0.8rem'}}
                                            >
                                                <i className='fa fa-refresh'></i> &nbsp;{pageContent.rSendOtp}
                                            </button>
                                    </Show>

                                    <button
                                        type="submit"
                                        disabled={!this.state.otp || !this.state.user}
                                        onClick={() => this.handleVerify()}
                                        className="btn btn-success mr-3"
                                        style={{fontSize: '0.8rem'}}
                                    >
                                   {pageContent.items[1].title}
                                    </button>



                                </div>
                        </Show>

                                <Show when={this.state.status === PageStatus.Submitting}>
                                    <Spinner animation="border" variant="primary" />
                                </Show>
                    </Container>
                </Modal.Body>
            </Modal>
        );
    }
}


// const MobileLoginFormRedux = reduxForm<any, any>({
//     form: 'labelsMobileLoginForm',
// })(MobileLogin);

// const mapStateToProps = (state: { adminUser: { adminUser: { phoneNumber: any, email: any, language: any, userId: any; token: any; loading: any; error: any; role: any ; apiToken: any}; }; }) => {
//     return {
//         userId: state.adminUser.adminUser.userId,
//         role: state.adminUser.adminUser.role,
//         phoneNumber: state.adminUser.adminUser.phoneNumber,
//         language: state.adminUser.adminUser.language,
//         email: state.adminUser.adminUser.email,
//         isLoading: state.adminUser.adminUser.loading,
//         error: state.adminUser.adminUser.error,
//         apiToken: state.adminUser.adminUser.apiToken,
//     };
// };

// const MobileLoginFormWithRouter = withRouter(connect(mapStateToProps) (MobileLoginFormRedux));

// export { MobileLoginFormWithRouter as MobileLogin };
const OtpVerify4RequestApprovalWithRouter = withRouter(OtpVerify4RequestApproval);

export { OtpVerify4RequestApprovalWithRouter as OtpVerify4RequestApproval };
