import React from 'react';
import { reduxForm, } from 'redux-form';
import { Alert, Modal, Spinner } from 'react-bootstrap';
import { withRouter } from 'react-router';
import { Show } from 'Layout';
import { PageStatus } from 'enums';
import { WhatsappTempAPI } from "../../API/WhatsappTempAPI";
import Swal from 'sweetalert2';
export type FormValue = {
  tempid: string,
};

type State = {
  status: string,
  error: string | null,
  data: any,
  tempid: string,
  type: string[],
};

class Form extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      status: PageStatus.None,
      error: null,
      tempid: "",
      type: "",
      details:[],
      options: [
        { label: "Normal", value: "normal" },
        { label: "Reminder One", value: "reminder_one" },
        { label: "Reminder Two", value: "reminder_two" },
    ],
    };
  }

  componentDidMount() {
    if (!!this.props.id) {
      console.log("id = ", this.props.id);
      // this.fetchDetails();
    } 
  }

  // fetchDetails() {
  //   Promise.resolve()
  //     .then(() => this.setState({ status: PageStatus.Loading }))
  //     .then(() => {
  //       if (!this.props.id) {
  //         return Promise.reject(new Error('Invalid ID'));
  //       }

  //       return WhatsappTempAPI.getOne(this.props.id);
  //     })
  //     .then((data) => {

  //       this.initializeValues(data);
  //       this.setState({
  //         data,
  //         status: PageStatus.Loaded,
  //       }, () => {
  //         this.fetchList()
  //       });
  //     })
  //     .catch((error) => {
  //       this.setState({ status: PageStatus.Error, error: error.message });
  //     });
  // }

  formValues() {
    return {
      tempid: this.state.tempid,
      type: this.state.type,
    };
  }

  // initializeValues(data) {
  //   const formattedExpiryDate = moment(data.expiryDate).format("YYYY-MM-DD");
  //   this.setState({ Newdateexpireset: formattedExpiryDate });
  //   console.log("update data = ", data);
  //   return this.setState({
  //     tempid: data.tempid,
  //   });
  // }

  onSubmit() {
    if (!this.props.id) {
      return this.create();
    }
    return this.update();
  }

  create() {
    const valuesIn = this.formValues();
    return Promise.resolve()
      .then(() => this.setState({ status: PageStatus.Submitting }))
      .then(() => WhatsappTempAPI.create(valuesIn))
      .then((data) => {
        this.props.onSubmit();
        return this.setState({ status: PageStatus.Submitted });
      })
      .catch((error) => {
        this.setState({ status: PageStatus.Error, error: error.message });
      });
  }

  update() {
    const valuesIn = this.formValues();
    return Promise.resolve()
      .then(() => this.setState({ status: PageStatus.Submitting }))
      .then(() => WhatsappTempAPI.update(this.props.id, valuesIn))
      .then((respo) => {
        this.setState({ status: PageStatus.Submitted });
        return this.props.onSubmit();
      })
      .catch((error) => {
        this.setState({ status: PageStatus.Error, error: error.message });
      });
  }

  reset() {
    return this.setState({
      data: "",
      tempid: "",
      type: "",
    });
  }

  handleTypeChange = (evt) => {
    this.setState({
      type: evt.target.value,
    });
  };

  render() {
    return (
      <Modal
        centered
        size="lg"
        backdrop="static"
        onHide={this.props.onClose}
        show={this.props.show}
        style={{ zIndex: 1201 }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Create Whatsapp Templete
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: '78vh', overflow: 'auto' }}>
          <Show when={this.state.status === PageStatus.Loading}>
            <div className="d-flex justify-content-center w-100 p-5">
              <Spinner animation="border" variant="primary" />
            </div>
          </Show>

          <Alert variant="danger" show={this.state.status === PageStatus.Error}>
            {this.state.error}
          </Alert>

          <form onSubmit={this.props.handleSubmit( (event) => this.onSubmit() )} >
            <div className="row mt-2">
              <div className="col">
                <label htmlFor="tempid">Templete ID*</label>
                <input
                  className="form-control"
                  name="tempid"
                  onChange={(e) => this.setState({ tempid: e.target.value.trim() })}
                  value={this.state.tempid}
                  placeholder="Enter here"
                  required
                />
              </div>
              <div className="col">
                <label htmlFor="text">Type*</label>
                <select 
                  name="type" 
                  id="type"
                  onChange={this.handleTypeChange}
                  value={this.state.type}
                  className='form-control'
                  required
                >
                  <option value=''>--Choose Type--</option>
                    {this.state.options.length > 0 ? this.state.options.map((ele, index) => (
                      <option key={index} value={ele.value}>{ele.label}</option>
                    )) : ''}
                </select>
              </div>
            </div>

            <hr />

            <Alert variant="danger" show={!!this.state.error} className="mt-2">
              {this.state.error}
            </Alert>

            <div className="d-flex align-items-center mt-2">
              <button
                type="submit"
                disabled={!this.state.tempid || !this.state.type}
                className="btn btn-primary mr-3"
              >
                Submit
              </button>

              <button
                type="button"
                disabled={false}
                onClick={() => this.reset()}
                className="btn btn-light mr-3"
              >
                Reset
              </button>

              <Show when={this.state.status === PageStatus.Submitting}>
                <Spinner animation="border" variant="primary" />
              </Show>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    );
  }
}

const dataFormRedux = reduxForm<FormValue, any>({
  form: 'dataForm',
})(Form);


const dataFormWithRouter = withRouter(dataFormRedux);

export { dataFormWithRouter as Form };
