import { makeStyles, Paper, Typography } from "@material-ui/core";
import { Assets, PageStatus } from "enums";
import { Container } from "@material-ui/core";
import { useEffect, useState } from "react";
import { AuthAPI } from "API";
import { Show } from "Layout";
import { Alert, Spinner } from "react-bootstrap";
import { useLocation } from "react-router";

// Define types for API response
interface Question {
    survey_id: string;
    id: number;
    created_at: string;
    updated_at: string;
    deleted_at?: string;
    status: string;
    question: string;
    answer: string;
}

function PreScreenQuesPage() {
    const [status, setStatus] = useState<PageStatus>(PageStatus.None);
    const [preScreenApiData, setPreScreenApiData] = useState<Question[]>([]);
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const [currentQues, setCurrentQues] = useState<Question | null>(null);
    const [selectedAnswer, setSelectedAnswer] = useState<string | null>(null);
    // const formData: { [key: string]: any }[] = [];
    const [formData, setFormData] = useState<{ question: string; answer: string }[]>([]);
    const location = useLocation();

    console.log(window.innerWidth);

    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
        },
        appBar: {
            marginBottom: theme.spacing(3),
        },
        content: {
            padding: theme.spacing(3),
            textAlign: 'left',
        },
        paper: {
            padding: theme.spacing(2),
            display: 'flex',
            overflow: 'auto',
            flexDirection: 'column',
            alignItems: 'center',
        },
        logo: {
            width: '100%',
            maxWidth: '400px', // Adjust the maximum width as needed
        },
        grow: {
            flexGrow: 1,
        },
        btn: {
            padding:"10px 20px",
            color: "black",
            backgroundColor: "linear-gradient(#e7e7e7, #f3f3f3)",
            borderRadius: "10px",
            border: "1px solid #8f8f8f",
            margin:"1rem",
            '&:hover': {
                backgroundColor: "#008083 !important", // Change background color on hover
                color:"#ffffff",
                transform: "scale(1.05)", // Slightly scale up the button
            },
        },
        progress: {
            background: "rgba(0,0,0,.2)",
            borderRadius: "5px",
            width: "30%", 
            height: "1rem",
    
            [theme.breakpoints.down('sm')]: {
                width: "80%",
            },
            [theme.breakpoints.up('sm')]: {
                width: "30%",
            },
            
        
        
        },
        footer: {
                    bottom: 10,
                    right: 10,
                    display: 'flex',
                    position: "fixed",
                    padding: "10px"
                    },
    }));

    const preScreenfetchList = async () => {
        setStatus(PageStatus.Loading);
        const data = {
            survey_id: location.pathname.split("/").at(-2)
        };
        try {
            const responseData: Question[] = await AuthAPI.getSurveyQuestions(data);
            setPreScreenApiData(responseData);
            setCurrentQues(responseData[0] || null);
            setStatus(PageStatus.Loaded);
        } catch (err) {
            console.error("Failed to fetch survey questions:", err);
            setStatus(PageStatus.Error);
        }
    };

    const nextButton = () => {
        if(selectedAnswer == null){
            alert("choose answer");
            return
        }
            if (currentIndex < preScreenApiData.length - 1) {
                setCurrentIndex(currentIndex + 1);
                setCurrentQues(preScreenApiData[currentIndex + 1]);
                setSelectedAnswer(null); // Reset selected answer for the new question
            }
    };

    const previousButton = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
            setCurrentQues(preScreenApiData[currentIndex - 1]);
            setSelectedAnswer(null); // Reset selected answer for the previous question
        }
    };

    const handleSelectAnswer = (answer: string) => {
        setSelectedAnswer(answer);

        // Check if the current question already exists in formData
        setFormData((prevFormData) => {
            const existingEntryIndex = prevFormData.findIndex(entry => entry.question === currentQues?.question);

            if (existingEntryIndex !== -1) {
                // Replace the existing answer
                const updatedFormData = [...prevFormData];
                updatedFormData[existingEntryIndex] = { question: currentQues?.question, answer };
                return updatedFormData;
            } else {
                return [...prevFormData, { question: currentQues?.question, answer }];
            }
        });
    }

      const handleSubmit = async () => {
        if(selectedAnswer == null){
            alert("choose answer");
            return
        } 
        const data = {
            survey_id: location.pathname.split('/').at(-2),
            user_id:location.pathname.split('/').at(-1),
            details: formData
        };
        console.log(data);

        try {
            const response = await AuthAPI.matchAnswers(data);
            // console.log(response, 'questions response');
        } catch (err) {
            console.error("Submission error:", err);
            

        }
    };

    

    const classes = useStyles();

    useEffect(() => {
        preScreenfetchList();
    }, []);

    return (
        <div className={classes.root}>
            <Container maxWidth="lg" className={classes.content}>
                <Paper className={classes.paper}>
                    <img alt="Logo" src={Assets.Logo2} className={classes.logo} />
                </Paper>

                <div className="mt-1">
                    <div
                        className=" w-100"
                        style={{textAlign:"center", marginTop:"2rem" }}
                    >
                        <div>{`${currentIndex+1}/${preScreenApiData.length}`}</div>
                        <Typography variant="h4" className="mt-5">
                            {currentQues ? currentQues.question : "Loading question..."}
                        </Typography>
                    </div>
                    <div className="w-100" style={{ display: "grid", placeItems: "center" }}>
                        <div
                            className="d-flex m-3"
                            style={{ justifyContent: "space-between" }}
                        >
                            <button
                                id={`yes${currentIndex}`}
                                onClick={() => handleSelectAnswer("yes")}
                                role="radio"
                                aria-checked={selectedAnswer === "yes"}
                                className={` ${classes.btn}`}
                            >
                                Yes
                            </button>
                            <button
                                id={`no${currentIndex}`}
                                onClick={() => handleSelectAnswer("no")}
                                role="radio"
                                aria-checked={selectedAnswer === "no"}
                                className={` ${classes.btn}`}
                            >
                                No
                            </button>
                        </div>
                    </div>

                    <div className="mt-5" style={{display:"grid", placeItems:"center", width:"100%"}}>
                        <div className={classes.progress}>
                            <div style={{background:"#008083",borderRadius:"5px", width:`${(currentIndex+1) / preScreenApiData.length * 100+"%" }`, height:"1rem"}}></div>
                            <div style={{textAlign:"end"}}>{(currentIndex+1) / preScreenApiData.length * 100 == 100? "Completed": (currentIndex+1) / preScreenApiData.length * 100+"%" }</div>
                        </div>
                    </div>

                </div>

                <div >
                    <div className={classes.footer}>
                        <button
                            type="button"
                            onClick={previousButton}
                            className="btn btn-primary mr-3"
                            disabled={currentIndex === 0}
                        >
                            Previous
                        </button>
                        <button
                            type="button"
                            onClick={currentIndex < preScreenApiData.length - 1 ? nextButton : handleSubmit}
                            className="btn btn-primary mr-3"
                        >
                            {currentIndex < preScreenApiData.length - 1 ? "Next" : "Submit"}
                        </button>
                        <button type="button" className="btn btn-light mr-3">
                            Cancel
                        </button>
                    </div>
                </div>
            </Container>
        </div>
    );
}

export default PreScreenQuesPage;