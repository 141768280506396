import React, { useEffect, useState, useCallback } from 'react';
import { reduxForm, InjectedFormProps, reset } from 'redux-form';
import { Modal, Spinner, Alert } from 'react-bootstrap';
import { withRouter, RouteComponentProps } from 'react-router';
import { PartnersAPI } from "../../API";

// Enum for page status
export enum PageStatus {
    None = 'NONE',
    Loading = 'LOADING',
    Loaded = 'LOADED',
    Error = 'ERROR',
    Submitting = 'SUBMITTING',
    Submitted = 'SUBMITTED'
}


export type FormValue = {
    name?: string;
    description?: string;
    successUrl: string;
    overQuotaUrl: string;
    disqualifiedUrl: string;
    badTerminatedUrl: string;
};

interface FormProps extends InjectedFormProps<FormValue>, RouteComponentProps {
    onClose: () => void;
    show: boolean;
    partnerId: string;
    partnerName: string;
}

const Form: React.FC<FormProps> = ({


    handleSubmit,
    onSubmit,
    onClose,
    show,
    partnerId,
    initialize,
    partnerName,
    reset
}) => {
    const [status, setStatus] = useState < PageStatus > (PageStatus.None);
    const [error, setError] = useState < string | null > (null);
    const [formValues, setFormValues] = useState < FormValue > ({
        successUrl: '',
        overQuotaUrl: '',
        disqualifiedUrl: '',
        badTerminatedUrl: ''
    });

    useEffect(() => {
        if (partnerId) {
            fetchDetails();
        }
    }, [partnerId]);

    const fetchDetails = useCallback(async () => {
        setStatus(PageStatus.Loading);
        try {
            if (!partnerId) throw new Error('Invalid ID');
            const data = await PartnersAPI.getOne(partnerId);
            const initialValues: FormValue = {
                name: data.name || '',
                description: data.description || '',
                successUrl: data.successUrl || '',
                overQuotaUrl: data.overQuotaUrl || '',
                disqualifiedUrl: data.disqualifiedUrl || '',
                badTerminatedUrl: data.badTerminatedUrl || ''
            };
            setFormValues(initialValues);
            initialize(initialValues);
            setStatus(PageStatus.Loaded);
        } catch (error) {
            setError(error.message || 'An error occurred');
            setStatus(PageStatus.Error);
        }
    }, [partnerId, initialize]);

    const onSubmitForm = async (event: React.FormEvent) => {
        console.log(partnerId, "partnerId");

        event.preventDefault();
        setStatus(PageStatus.Submitting);
        try {
            const response = await PartnersAPI.updatePartner(formValues, partnerId);
            onSubmit(response.id);
            setStatus(PageStatus.Submitted);
        } catch (error) {
            setError(error.message || 'An error occurred');
            setStatus(PageStatus.Error);
        }
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormValues(prevValues => ({ ...prevValues, [name]: value }));
    };

    if (!show) return null;

    return (
        <Modal
            centered
            size="lg"
            backdrop="static"
            onHide={onClose}
            show={show}
            style={{ zIndex: 1201 }}
        >
            <Modal.Header closeButton>
                <Modal.Title>Add Partner Name: <span className='text-info'>{partnerName}</span></Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ maxHeight: '78vh', overflow: 'auto' }}>
                {status === PageStatus.Loading && (
                    <div className="d-flex justify-content-center w-100 p-5">
                        <Spinner animation="border" variant="primary" />
                    </div>
                )}
                {status === PageStatus.Error && (
                    <Alert variant="danger">{error}</Alert>
                )}
                <form onSubmit={onSubmitForm}>
                    <div className="form-group">
                        <label htmlFor="successUrl">Completed URL*</label>
                        <input
                            name="successUrl"
                            type="text"
                            className="form-control"
                            placeholder="Enter..."
                            value={formValues.successUrl}
                            onChange={handleInputChange}
                            required
                        />
                        <label htmlFor="overQuotaUrl">Quota Full URL*</label>
                        <input
                            name="overQuotaUrl"
                            type="text"
                            className="form-control"
                            placeholder="Enter..."
                            value={formValues.overQuotaUrl}
                            onChange={handleInputChange}
                            required
                        />
                        <label htmlFor="disqualifiedUrl">Terminate URL*</label>
                        <input
                            name="disqualifiedUrl"
                            type="text"
                            className="form-control"
                            placeholder="Enter..."
                            value={formValues.disqualifiedUrl}
                            onChange={handleInputChange}
                            required
                        />
                        <label htmlFor="badTerminatedUrl">Bad Terminate URL*</label>
                        <input
                            name="badTerminatedUrl"
                            type="text"
                            className="form-control"
                            placeholder="Enter..."
                            value={formValues.badTerminatedUrl}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <hr />
                    {status === PageStatus.Error && (
                        <Alert variant="danger" className="mt-2">
                            {error}
                        </Alert>
                    )}
                    <div className="d-flex align-items-center mt-2">
                        <button
                            type="submit"
                            disabled={status === PageStatus.Submitting}
                            className="btn btn-primary mr-3"
                        >
                            Submit
                        </button>
                        <button
                            type="button"
                            className="btn btn-light mr-3"
                            onClick={() => {
                                setFormValues({
                                    successUrl: '',
                                    overQuotaUrl: '',
                                    disqualifiedUrl: '',
                                    badTerminatedUrl: ''
                                });
                                reset(); // Reset form using redux-form's reset action
                            }}
                        >
                            Reset
                        </button>
                        {status === PageStatus.Submitting && (
                            <Spinner animation="border" variant="primary" />
                        )}
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
};

const FormRedux = reduxForm < FormValue > ({
    form: 'partnerForm', // Ensure this is unique for each form
    enableReinitialize: true,
})(Form);

const serveFormWithRouter = withRouter(FormRedux);

export { serveFormWithRouter as Form };
